.bloglistcol{
    background-color: #fff;
}
.blogCard{
    /* margin-left: 2px; */
}
.postBody{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}
.postImage{
    width: 100%;
    height: 15vw;
    object-fit: cover;
}
.readMorePage{
    background-color: maroon;
    border: none;
    border-radius: 10px;
    font-size: 12px;
}
.readMorePage:hover{
    background-color: orange;
    border: none;
    border-radius: 10px;
    font-size: 12px;
}