.loginWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    min-height: 100vh;
    width: 100%;
    background-color: bisque;
}
.loginBox{
    border-radius: 6px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    height: 400px;
    width: 400px
}
.formHead{
    margin: 1rem;
    text-align: center;
}
.loginFormForm{
    margin: 1.5em;
}
.formFoot{
    text-align: center;
}
.loginformLink{
    color: orange !important;
}
.errorRef{
    color: red;
    font-size: 12px;
}