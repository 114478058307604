.postColWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.postHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgb(212, 205, 205);
}
.titlePost{
    margin: 5px;
    text-decoration: none;
    color: maroon;
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
}
.postButtons{
    padding: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.postEdit{
    font-size: 20px;
    margin: 5px;
    color: #000;
}
.postEdit:hover{
    font-size: 17px;
    color: maroon;
}
.postDelete{
    font-size: 20px;
    margin: 5px;
    color: #000;
}
.postDelete:hover{
    font-size: 17px;
    color: maroon;
}
.postAllDetails{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.postCoverImage{
    width: 30%;
    margin: 2px;
    height: fit-content;
}
.postImageCover{
    width: 100%;
    height: 170px;
    padding: 5px;
    border-radius: 8px;
    object-fit: cover;
}
.postTextDetails{
    position: relative;
    display: flex;
    height: 100%;
    width: 1400px;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
}
.postDateAuthor{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.postTextBody{
    top: 0px;
    margin-top: 5px;
    position: relative;
}
.bodyText{
    margin: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Lato', sans-serif;
    /* font-family: 'PT Sans', sans-serif; */
    font-weight: lighter;
    overflow: hidden;
}
.postTextAuthor{
    bottom: 0px;
    margin-top: 5px;
    position: relative;
}
.authorText{
    margin: 5px;
    color: rgb(163, 68, 68);
}
.postTextDate{
    bottom: 0px;
    margin-top: 5px;
    position: relative;
}
.dateText{
    margin: 5px;
    color: rgb(181, 178, 178);
}