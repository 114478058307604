.bloglistcol{
    background-color: #fff;
}
.blogCard{
    margin-left: 2px;
}
.postBody{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.postImage{
    width: 100%;
    height: 15vw;
    object-fit: cover;
}
.toSinglePage{
    text-decoration: none;
    color: maroon;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px dotted #ddd;
}
.readMorePage{
    background-color: maroon;
    border: none;
    border-radius: 10px;
    font-size: 12px;
}
.readMorePage:hover{
    background-color: orange;
    border: none;
    border-radius: 10px;
    font-size: 12px;
}