.about-section{
    font-family: 'Roboto', sans-serif;
    height: auto;
    scroll-margin-top: 90px !important;
    margin-top: 40px;
    margin-bottom: 50px;


}
.about-wrapper{
    width: 95%;
    margin-right: -12px;
}

.form-section{
    background-color: rgba(252, 243, 226, 0.603);
    width: 100%;
    padding: 10px;
    height: 480px;
}
.about-tenda h6{
    color: orange;
    margin-top: 25px;
    text-align: left;
}
.about-tab-heads{
    font-weight: bold;
}
.our-story h4{
    text-align: left;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
}
.story{
    text-align: left;
    font-size: .9rem;
    padding-right: 1rem;
    font-family: 'PT Sans', sans-serif;
}
.tenda{
    display: flex;;
    justify-content: center;
    align-items: flex-start;
    margin: 2em 0em 0em -2em;
    
}
.tenda-box{
    padding: 0.5rem;
    width: max-content;
    min-width: max-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: rgba(252, 243, 226, 0.603);
    border-radius: 5px;

}
.tenda-box:hover{
    background-color: maroon;
    border: solid 0.01rem white;
}

.tenda-box h6{
    margin: 5px;
    font-size: 1.2rem;
    padding: auto;
}
.right-header{
    margin: 1em 0em 0em 0em;
    padding: 2px;
}
.right-header h5{
    font-weight: bold;
    color: maroon;
}

.button3{
    background-color: orange !important;
    color: #ffffff;
    border: none;
}
.button-in-about{
    background-color: none !important;
    border: none;
    cursor: pointer;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}
.our{
    font-size: 0.8rem;
    margin-left: 5px !important;
    border: none;
    color: #696969;
    outline: none;
    background: none!important;
    padding: 0!important;
    text-decoration: none;
    cursor: pointer;
}
.our:hover{
    color: #ffffff !important;
}
.nav-pills .nav-link.active{
    color: #069;
}
.short .form-section{
    display: flex;
    margin-bottom: 0;
    align-items: flex-start;
    background-color: rgba(252, 243, 226, 0.603);
    justify-content: stretch;
    min-height: 100%;
    
}
.sameh{
    display: flex;
    align-items: stretch;
    background-color: aqua;
}
.samehh-1{
    min-height: 100%;
}
.samehh-2{
    min-height: 100%;
}
.pane-image{
    margin-top: 2em;
}
.not-this{
    background-color: rgba(252, 243, 226, 0.603);
    padding: 8px;
    height: 620px;
    max-height: 600px;
    margin-bottom: 3em;
}
.keys{
    font-size: 12px;
    margin-top: -10px;
}
.now-this{
    font-size: 14px;
}
.nav-pills .nav-link.active{
    color: orange !important;
    font-weight: bold;
}
.nav-pills .nav-link:hover{
    color: #fff;
    font-weight: bold;
}
.form-labelz{
    height: 30px !important;
}
.form-labez{
    margin-top: 10px;
}
input{
    font-size: 12px;

}
.form-message.active{
    color: #000;
}
.tenda-building{
    background-color: #069;
    height: 300px;
    max-height: 300px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-size: cover;

    /* background-image: url("../../assets/images/thouse.jpg"); */
    background-image: url("https://img.freepik.com/free-photo/portrait-two-african-american-young-people-using-computers-while-working-software-development-office-copy-space_236854-34261.jpg");
}
.talk2us-button{
    background-color: maroon;
    border: solid 1px maroon;
    font-weight: bold;
}
.talk2us-button:hover{
    border: none;
    background-color: #ec9706;
}
/*css for the mobile about section*/
.about-wrapper2{
    width: 80%;
    height: auto;
}
.about-tenda-mobi h6{
    color: orange;
    margin-top: 25px;
    text-align: center;
}
.our-story-mobi h4{
    text-align: center;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
    /* font-size: 2rem; */
}
.story-mobi{
    text-align: left;
    font-size: 13px;
    font-family: 'PT Sans', sans-serif;
}
.what-makes-us-tenda h6{
    font-family: 'PT Sans', sans-serif;
    color: #ec9706;
    text-decoration: underline;

}
.what-makes-us-tenda p{
    font-size: 13px;
    font-family: 'PT Sans', sans-serif;
}
.what-makes-us-tenda ul li{
    font-size: 13px;
    font-family: 'PT Sans', sans-serif;
}
