.registerWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    min-height: 100vh;
    width: 100%;
    background-color: bisque;
}
.registerFormForm{
    margin: 1rem;
}