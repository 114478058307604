.carousel{
    height: 565px !important;
    background-color: var(--bs-body-color);
}
.swiper-main{
    height: 565px;

}
.calousel-wrapper{
    /* background-color: aqua; */

}
/* all items left css styles*/
.calousel-left{
    width: 50%;

}
.text-buttons{
    text-align: center;
    color: black;
}
.hero-text{
    margin-top: 5em;
    color: #ffffff;
    padding: 5px;
    text-align: left;       
}
.hero-para1{
    margin-top: 5rem;
    font-size: calc(21px - 5px);
    font-family: 'PT Sans', sans-serif;
    
}
.hero-para2{
    font-size: calc(21px - 5px);
    font-family: 'PT Sans', sans-serif;
    
}
.hero-head{
    /* font-size: 4.8vw; */
    font-size: calc(48px - 5px);
    color: #fff;
    /* font-family: sans-serif; */
    font-family: 'PT Sans', sans-serif;
    font-weight: 600;
    line-height: 0.9em
}
.button1{
    border: none;
    outline: 0;
    display: inline-block;
    color: #ffffff;
    background-color: maroon;
    text-align: center;
    cursor: pointer;
}
.button2{
    border: 1px solid #ffffff;
    outline: 0;
    display: inline-block;
    color: #ffffff;
    background-color: var(--bs-body-color);
    text-align: center;
    cursor: pointer;
}
.button1:hover{
    background-color: #555;
    color: white;
}
.button2:hover{
    background-color: #555;
    color: white;
}
.hero-svg{
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    
}
.scribbled{
    height: 35px;
}
.hero-text2{
    text-align: left;
    font-size: 12px;
    color: #ffffff;
}
.hero-svg2{
    position: absolute;
    opacity: .85;
    top: 44%;
    left: 43%;
}
.mysvg{
    position: absolute;
    top: 90%;
    z-index: 0;
}


/* all items right css styles*/
.calousel-right{
    width: 50%;
}
.hero-img{
    position: relative;
    width: 100%;
    height: 100%;
}
.hero1{
    position: absolute;
    height: 90%;
    width: 100%;
    top: 30%;
    z-index: 999;
    border-top-left-radius: 5em;
    border-bottom-right-radius: 5em;
}
