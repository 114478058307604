.sidebarWrapper{
    flex: 2;
}
.sidebar{
    padding: 10px;
    margin-top: 2em;
    height: 1150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}
.sidebar h6{
    font-size: 1.2rem;
    color: rgb(139, 64, 64);
}

.cardBar{
    display: flex;
    color: #6c757d!important;
    border-bottom: solid 1px #eee;
    justify-content: space-between;
    align-items: stretch;
    font-size: .8rem;
}
/*posts*/
.postsWrapper{
    margin-top: 0px;
    height: auto;

}
.postsWrapperHead{
    border-bottom: 1px solid #adb5bd;
    padding: 2px;
    background-color: rgba(252, 243, 226, 0.603);
    border-top: 1px solid #adb5bd;
}
.postAuthor{
    font-size: .8rem;
    margin-top: -3px;
    color: black;
}
.postDate{
    font-size: .7rem;
    color: grey;
    margin-right: .5rem;

}
.postTitle{
    color: maroon;
    font-weight: 400;
    text-decoration: none !important;
    line-height: 20px;
}
.postTitle:hover{
    color: rgb(45, 44, 44);
    font-weight: 400;
    text-decoration: none !important;
    line-height: 20px;
}
.postTitleLink{
    text-decoration: none !important;
}
.post-text{
    overflow: hidden;
    text-overflow: ellipsis;  
    margin-top: -8px;
    display: -webkit-box;
    line-height: 20px;     /* fallback */
    max-height: 60px;      /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;   
}
.cardFooter{
    background-color: beige;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    /* border: solid 1px #6c757d ; */
    border: none;
}
.cardBodyMain{
    display: flex;
    position: relative;
    padding: 5px;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px solid #adb5bd;
    margin: -7px 10px 10px -5px;
}
.cardtext-data{
    display: flex;
    height: 20px;
    width: 200px;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.cardImageMain{
    width: 60px;
    height: 40px;
}
/*events*/
.eventWrapper{
    margin-top: 1em;
    height: auto;
    margin-bottom: 2em;


}
.eventWrapper .box {
    position: relative;
    width: inherit;
}

.eventWrapper .box .text {
    position: relative;
    z-index: 999;     
    padding: 5px;
    text-align: left;
    bottom: 0;
    left: 0;
    background: rgba(128, 0, 0, 0.8);
    color: #fff;
    width: inherit; /* Set the width of the positioned div */
    height: 20%;
}
.eventWrapper .box .eventBody{
    font-weight: lighter;
}

/*trending*/
.trendingProductWrapperr{
    margin-top: 0px;

}
