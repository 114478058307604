.addUserWrapper{
    margin-top: 20px;
    display: flex;
    width: 80%;
    flex-direction: column;
    text-align: left;
    align-items: stretch;
    justify-content: center;
}
.adduserForm label{
    font-size: 18px;
    margin: 20px 20px 20px 0px
}
.adduserForm input{
    font-size: 12px;
}
.addUserButton{
    outline: none;
    border: none;
    color: white;
    margin-top: 10px;
    width: 150px;
    align-self: flex-start;
    font-size: 18px;
    background-color: black;
    border-radius: 6px;
    padding: 5px
}
.addUserButton:hover{
    color: bisque;
    
}
