.partners-section{
    scroll-margin-top: 90px !important;
}
.partners-wrapper{
    width: 90%;
    font-family: 'Roboto', sans-serif;
    margin-top: 2em;
    height: 90vh;
}
.partners-left{
    width: 50%;
}
.partners-right{
    width: 50%;
    height: 400px;
}
.trust-us h6{
    color: orange;
    margin-top: 25px;
    text-align: left;
}
.our-partners h4{
    text-align: left;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
}
.why-trust{
    text-align: left;
    font-size: 13px;
    font-family: 'PT Sans', sans-serif;
}
.the-partners{
    margin: 5em 0em 5em 0em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
.slider-imagez{
    height: 50px;
    width: 120px;
}

.the-partners .carousel-control-next-icon{
    background-color: orange !important;
    color: orange !important;
    border-radius: 5px;
}
.the-partners .carousel-control-prev-icon{
    background-color: orange;
    color: orange !important;
    border-radius: 5px;
}
.partner-image-wrapper{
    background-image: url("../../assets/images/partner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 5em;
    border-bottom-left-radius: 5em;
    height: 100%;
    width: 100%;
}
.partner-image{
    height: 100%;
    width: 100%;
    margin-top: 2em;
    border-top-left-radius: 5em;
    border-bottom-left-radius: 2em;
}
.partner-image-wrapper2{
    width: 400px;
    height: 350px;
    position: relative;
}
.partner-image2{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.the-real-partner{
    background-image: url("../../assets/images/p1.PNG");
    background-size: cover;
    width: 130px;
    height: 75px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 50px -50px inset; */
    
    
}
.the-real-partner1{
    background-image: url("../../assets/images/p2.png");
    background-size: cover;
    width: 200px;
    height: 60px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 50px -50px inset; */
   
    
}
.the-real-partner2{
    background-image: url("../../assets/images/p3.png");
    background-size:cover;
    width: 127px;
    height: 105px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 50px -50px inset; */
    
}
.the-partners-small{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    
}