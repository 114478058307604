.lower-footer-section{
    background-color: rgb(85, 5, 5);
    font-family: 'PT Sans', sans-serif;
}
.lower-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.big-header{
    padding: 20px;
    
}
.big-header > .header{
    color: #ffffff; 
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 1.5vw;
}
.social > .icons{
    margin: 10px 5px 10px 5px;
    padding: auto;
    color: #ffffff;
    
}
.social > .icons:hover{
    padding: auto;
    color: orange;
    
}
.policies a{
    padding: 10px;
    text-decoration: none;
    color: #ffffff;
    font-weight: lighter;
    font-size: 1vw;
    
}
.policies a:hover{
    color: orange;
    text-decoration: underline;
}
.copyright{
    color: maroon;
    padding: 5px;
    font-size: 1vw;
}
#company-name{
    color: orange;
}