.blogPage{
    background-color: #fff;
    margin-top: 5rem;
}
.blogPagewrapper{
    /* background-color: #E7E9EB; */
}
.blog-banner{
    background-image: url("../../../../assets/images/hero1.jpg");
    /* background-color: #E7E9EB; */
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 40vh;
    /* margin-top: 80px; */
    margin-bottom: 2rem;
    font-family: 'PT Sans', sans-serif;

}
.blog-banner .overlay {
    background:rgba(0,0,0,0.3);
    overflow: hidden;
    height: 100%;
}
.blogBanner-text{
    margin: auto;
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    
}
.blogBanner-head{
    color: #fff;
    font-weight: bold;
    margin-top: 25px;

}
.blogBanner-para{
    text-align: center;
    color: #fff;
}
.crumb-para h1{
    font-weight: bold;
}
.left-1{
background-color: maroon;
}
.blogHeader{
    color: #fff;
    padding: 8px;
    
}
.blogCategories{
    position: -webkit-sticky;
    position: sticky;
    top: 5.5rem;
}
    
.blogCategories ul{
    list-style: none;
    margin-left: 0px !important;
    text-align: left;
    text-decoration: none;
    background-color: red;
}
.blogCategories ul li{
    /* padding: 5px; */
    text-align: left;
    margin-left: 0px;
}
.blogCategoryTitle{
    margin-bottom: 3rem;
}
.start-line{
    text-transform: uppercase;
    color: #717171;
    font-weight: 500;
    font-size: .8rem;
}
.list-group-item.active{
    background-color: bisque !important;
    color: black !important;
    font-weight: 500 !important;
    border-color: maroon !important;
}
.singlePostWrapper{ 
    display: flex;
    flex-direction: column;
}
.singlePostCover{
    /* background-color: aquamarine; */
    max-height: 350px;
    margin: 0px 10px 10px 10px;
}
.singlePostImage{
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    max-height: 340px;
    object-fit: cover;
}
.singlePostPublishDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px 10px 10px;
}
.singlePostAuthor{
    color: grey;
}
.singlePostCategory{
    color: rgb(224, 184, 111);
    font-size: 14px;
}
.singlePostDate{
    color: maroon;
    font-size: 14px;
}
.singlePostTitle h2{
    /* margin: 10px 10px 10px 20px; */
}
.singlePostBody p{
    /* margin: 0px 10px 10px 10px; */
}