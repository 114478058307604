.crumb-banner2{
    background-image: url("../../assets/images/banner2.jpg");
    /* background-color: red; */
        background-attachment: fixed;
        background-size: cover;
        height: 60vh;
        margin-top: 80px;
        font-family: 'PT Sans', sans-serif;

}
.overlay {
    /* background: rgba(115, 153, 238, 0.6); */
    /* background: rgba(79, 99, 141, 0.6); */
    background:rgba(0,0,0,0.3);
    overflow: hidden;
    height: 100%;
}
.breadcrumb a{
    text-decoration: none;
    color: #fff;
}
.breadcrumb >.active{
    color: #cc5500;
    text-decoration: none;
 }
 
.crumb-link{
    padding: 10px;
    margin-left: 125px;
}
.crumb-text{
    /* clear: both; */
    /* width: 80%; */
    margin: auto;
    width: 60%;
    /* border: 5px solid #FFFF00; */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    
}
.crumb-head{
    color: #fff;
    font-weight: bold;

}
.crumb-para{
    text-align: center;
    color: #fff;
}
.crumb-contact{
    border: none;
        outline: 0;
        display: inline-block;
        margin: 10px 10px 10px 0px;
        /* padding: 10px 25px; */
        color: #ffffff;
        background-color: orange;
        text-align: center;
        cursor: pointer;
}

/* Css for the contact section of the contact page*/
.reachus-section{
    background-color: rgba(219, 219, 215, 0.627);
    font-family: 'PT Sans', sans-serif;
}
.reachus-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}
.reachus-left{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    line-height: 15px;
    /* justify-content: center; */
    align-items: flex-start;
}
.reachus-left > div:first-child {
    margin-top: 5em;
    }
.reachus-box{
    display: flex;
    justify-content: stretch;
    border-top-left-radius: 6px;
    align-items: center;
    padding: 5px;
    margin: 5px 5px 5px 5px;
    width: 100%;
    background-color: #fff;
}
.reachus-icon{
    background-color: #edceb7;
    border-radius: 50%;
    margin-left: 10px;
    padding: 20px;
    /* display: inline-block; */
    height: fit-content;
    width: fit-content;
}
.reachus-text{
    padding: 10px;
    margin-left: 15px;
    /* line-height: 1px; */
    text-align: left;
}
.contact2{
    margin-top: -12px;
}
.contact3{
    margin-top: -12px;
}
.reachus-text p{
    font-size: 12px;
    color: maroon;
}
.reachus-text h6{
    font-size: 14px;
    font-weight: bold;
}
.reachus-right{
    margin-bottom: 5em;
    
}

/*for css*/
.form > h1 {
    border-bottom: 1px solid white;
    color: #3d3d3d;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding: 10px 10px 10px 0;
    text-align: left;
  }
 
  .form {
    background: white;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 5em 0 auto 2em;
    /* max-width: 500px; */
    padding: 30px 50px;
  }
 
  input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
  }
 
  label {
    color: #3d3d3d;
    display: block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
 
  .error {
    color: red;
    font-family: sans-serif;
    font-size: 12px;
    height: 30px;
  }
 
  .submitButton {
    background-color: maroon;
    color: white;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 14px;
    margin: 20px 0px;
  }
  /*css for the map-section*/
  .map-wrapper{
      width: 80%;
      font-family: 'PT Sans', sans-serif;
  }
  .map-wrapper h4{
      text-align: center;
      margin-top: 2em;
      /* font-size: 18px; */
  }
  .map-wrapper p{
      text-align: center;
      margin-bottom: 3em;
      font-size: 12px;
  }
  .map{
      margin-bottom: 2em;
  }