.crumb-banner-careers{
    background-image: url("../../assets/images/hero1.jpg");
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    height: 60vh;
    margin-top: 80px;
    font-family: 'PT Sans', sans-serif;

}
.overlay {
    background:rgba(0,0,0,0.3);
    overflow: hidden;
    height: 100%;
}
.breadcrumb a{
    text-decoration: none;
    color: #fff;
}
.breadcrumb >.active{
    color: #cc5500;
    text-decoration: none;
 }
 .crumb-link-jobsingle{
    padding: 10px;
    margin-left: 125px;
 }
.crumb-link{
    padding: 10px;
    margin-left: 125px;
}
.crumb-text{
    margin: auto;
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    
}
.crumb-head{
    color: #fff;
    font-weight: bold;

}
.crumb-para{
    text-align: center;
    color: #fff;
}
.crumb-para h1{
    font-weight: bold;
}
.crumb-contact{
    border: none;
    outline: 0;
    display: inline-block;
    margin: 10px 10px 10px 0px;
    color: #ffffff;
    background-color: orange;
    text-align: center;
    cursor: pointer;
}
/* css for the team section in careers*/
.team-section{
    margin-top: 3em;
}
.team-section-wrapper{
    width: 80%;
}
.career-mission-head{
    width: 60%;
}
.career-mission-head h2{
    color: maroon;
}
.career-mission-para{
    font-size: 16px;
}

/*css for the perks section*/
.perks-section{
    /* background-color: #ec9706; */
    background-color: #f4e8d2;
    margin-top: 2em;
}
.perks-section-wrapper{
    width: 80%;
}
.perks-head h2{
    margin-top: 2em;
    color: maroon;
}
.col-perks-para{
    width: 100%;
}
.perks-para{
    margin-top: 2em;
    width: 100%;
    position: relative;

}
.perks-para ul{
    list-style: none;
    line-height: 3rem;
}
.perks-para ul li{
    font-size: 16px;
    line-height: 30px;
}
.perks-para ul li::before{
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    padding: 2px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/mark.png");
    
}
/*jobs section css*/
.jobs-section{

}
.jobs-section-wrapper{
    width: 80%;
}
.jobs-head h2{
    color: maroon;
    margin-top: 1em;
}
.jobs h1{
    color: #cc5500;
    height: 100px;
}
/*value section css*/
.values-section{
    margin-top: 2em;
    display: block;
    position: relative;
}
.values-section-wrapper{
    width: 80%;
    display: block;
}
.values-head h2{
    color: maroon;
    margin-top: 1em;
}
.values-head p{
    width: 60%;
    font-size: 18px;
}
.core-values-carousel{
    margin-top: 2em;
}
.values-img{
    border-radius: 8px;
}
/*office location css*/
.office-location-section{
    display: block;
    margin: auto;
}
.office-location-head{
    width: 80%;
}
.office-location-head h2{
    color: maroon;
    margin-top: 1em;
}