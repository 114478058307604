.product-section{
    height: auto;
    background-color: rgba(252, 243, 226, 0.603);
    font-family: 'Roboto', sans-serif;
    scroll-margin-top: 90px !important;
}
.our-products{
    height: auto;
    width: 90%;
    /* background-color: rgba(241, 239, 225, 0.99); */
}
.we-done h6{
    padding-top: 2em;
    color: orange;
    text-align: center;
}
.our-product h4{
    font-weight: bold;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
}
.product-para{
    font-size: 13px;
    font-family: 'PT Sans', sans-serif;
    text-align: center;
    /* margin-left: 12%; */
}
.portfolio-buttons{
    border: none;
        outline: 0;
        display: inline-block;
        margin: 10px 10px 10px 0px;
        /* padding: 10px 25px; */
        color: #ffffff;
        background-color: maroon;
        text-align: center;
        cursor: pointer;
}
.portfolio-buttons:active{
    border: none !important;
    background-color: orange !important;
    outline: none !important;
    color: black !important;
}
.portfolio-buttons:hover{
    border: none !important;
    background-color: orange !important;
    color: black !important;
}
.product-description{
    font-size: 13px;
}
