.myMenu{
    width: 100%; 
    /* height: 80px; */
    top: 0;
    position: fixed;
    /* display: block; */
    background-color: #580000;
}
.navLink{
    color: #C0C0C0 !important;
    padding: 15px !important;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none !important;
    font-family: 'Roboto', sans-serif;
}
.navLink:hover{
    color: #fff !important;
    
}
.dinklink{
    text-decoration: none;
}

.navButton{
    color: maroon;
    background-color: #fff !important;
    border: none;
    font-weight: bold;
    /* margin-top: 8px; */
    font-size: 14px !important;
    display: inline;
    
}
.navButton:hover{
    color: maroon;
    border: solid #ffffff 2px;
}
.navbutton-link{
    text-decoration: none;
}
.contact, .careers, .services, .partners, .blog{
    text-decoration: none !important;
}