
.orange-box{
    /* content: ""; */
    border: solid 2px orange;
    height: 110px;
    width: 70px;
    position: absolute;
    opacity: .45;
    left: 47%;
    margin-top: -3em;
    z-index: 0;
}
.services-section{
    background-color: rgba(252, 243, 226, 0.603);
    font-family: 'Roboto', sans-serif;

}
.our-services{
    width: 90%;
    padding-bottom: 10px !important;
}
.we-do h6{
    padding-top: 2em;
    color: orange;
    text-align: center;
}
.our-service h4{
    font-weight: bold;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
}
.service-para{
    font-size: 13px;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
}
.grid-icon{
    margin-top: 10px;
    margin-left: 1em;
}
.services-grid{
    padding-bottom: 5px;
}
.show-grid{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    margin-bottom: 4em;
}
.show-grid2{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    margin-bottom: 2em;
}
 .grid1{
     background-color: #ffffff;
     margin-top: 2em;
     background-image: url("../../assets/images/ideation2.png");
     background-size: contain;
     background-position: center;
     background-repeat: no-repeat;
     border-radius: 5px;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     /* border: solid .5px #000; */
 }
 .grid1 h6{
    text-align: center;
    margin-top: 3px;
    color: maroon;
    margin-bottom: 2em;
 }
 .grid2 h6{
    text-align: center;
    margin-top: 3px;
    color: maroon;
 }
 .grid3 h6{
    text-align: center;
    margin-top: 3px;
    color: maroon;
 }
 .grid4 h6{
    text-align: center;
    margin-top: 3px;
    color: maroon;
 }
 .grid5 h6{
    text-align: center;
    margin-top: 3px;
    color: maroon;
 }
 .grid6 h6{
    text-align: center;
    margin-top: 3px;
    color: maroon;
    margin-bottom: 2em;
 }
 .grid2{
     background-color: #ffffff;
     margin-top: 2em;
     border-radius: 5px;
     /* border: solid .5px #000; */
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center;
     background-image: url("../../assets/images/webdev2.png");

 }
 .service-overlay{
    /* background:rgba(0,0,0,0.2); */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    width: 100%;
 }
 .grid3{
     background-color: #ffffff;
     margin-top: 2em;
     border-radius: 5px;
     /* border: solid .5px #000; */
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
/* background-color: aqua; */
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center;
     background-image: url("../../assets/images/pdesign2.png");
 }
 .grid4{
    background-color: #ffffff;
    margin-bottom: 2em;
    border-radius: 5px;
    /* border: solid .5px #000; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-size: contain;
     background-position: center;
     background-repeat: no-repeat;
     background-image: url("../../assets/images/mdev2.png");
}
.grid5{
    background-color: #ffffff;
    margin-bottom: 2em;
    border-radius: 5px;
    /* border: solid .5px #000; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-size: contain;
     background-position: center;
     background-repeat: no-repeat;
    background-image: url("../../assets/images/visual2.png");

}
.grid6{
    background-color: #ffffff;
    margin-bottom: 2em;
    border-radius: 5px;
    /* border: solid .5px #000; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-size: contain;
     background-position: center;
     background-repeat: no-repeat;
    background-image: url("../../assets/images/dtrans2.png");

}
.grid-service{
    padding: .5em;
    margin-top: 2em;
    text-align: center;
}
.grid-service h6{
    font-weight: bolder;
    color: blue;
    
}
.h6grid h6{
    margin-top: 10px;
}
.h6grid h6:hover{
    color: black;
}
.ServicesgridLinks{
    text-decoration: none;
}